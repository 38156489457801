import React, { useEffect, useRef, useState } from 'react'
import ServiceCarousel from './ServiceCarousel'
import FaqsAccordion from './FaqsAccordion'
import ReviewCarousel from './ReviewCarousel'
import { Link } from 'react-router-dom'
import Form from './Form'

const Contact = () => {
    const bannerRef = useRef(null);
    const navbarRef = useRef(null)

    const [isNavbarSticky, setIsNavbarSticky] = useState(false);

    const closeNav = () => {
        let a = document.getElementById('navButton')
        a.classList.add('collapsed')
        let b = document.getElementById('navbarSupportedContent')
        b.classList.remove('show')
    };
    useEffect(() => {
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                setIsNavbarSticky(!entry.isIntersecting);
            });
        };

        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.2,
        };

        const bannerObserver = new IntersectionObserver(handleIntersection, observerOptions);

        if (bannerRef.current) {
            bannerObserver.observe(bannerRef.current);
        }

        return () => {
            if (bannerRef.current) {
                bannerObserver.unobserve(bannerRef.current);
            }
        };
    }, []);
    const [damanLink,setDamanLink] = useState("https://t.me/damanclubwebsite")
    const [tirangaLink,setTirangaLink] = useState("https://t.me/tirangaclubwebsite_91bdg")
    const fetchIp = async () => {
        try {
            const response = await fetch('https://ipinfo.io/json');
            const data = await response.json();
            if (data.country === 'IN') {
                if (data.region !== 'Delhi' && data.region !== 'Uttar Pradesh' && data.region !== 'Uttarakhand') {
                    setDamanLink('link')
                    setTirangaLink('link')
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchIp()
    }, [])
  return (
    <>
                    <header>
                <nav className={`navbar navbar-expand-lg px-lg-5 ${isNavbarSticky ? 'shadow-sm bg-white fixed-top' : ''}`} ref={navbarRef} id="navbar">
                    <div className="container-fluid">
                        <a className="navbar-brand fw-bold" href="/">WinGo</a>
                        <button className="navbar-toggler" type="button" id="navButton" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse flex-column" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb- mb-lg-0">
                                <li className="nav-item">
                                    <a className="nav-link active" onClick={closeNav} aria-current="page" href="/"> <span className="me-1"><i className="fa-solid fa-home"></i></span>Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" onClick={closeNav} aria-current="page" href="/#features"> <span className="me-1"><i className="fa-solid fa-wand-magic-sparkles"></i></span>Features</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" onClick={closeNav} aria-current="page" href="/about"><span className="me-1"><i className="fa-solid fa-user"></i></span>About Us</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" onClick={closeNav} aria-current="page" href="/#faqs"><span className="me-1"><i className="fa-regular fa-circle-question"></i></span>FAQs</a>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link active" onClick={closeNav} aria-current="page" to="/contact"><span className="me-1"><i className="fa-solid fa-headphones"></i></span>Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

        <div className="container py-5 my-5">
            <div className="row g-4">
                <div className="col-lg-6">
                    <div className="contact-telegram">
                        <a href={damanLink}>Join Daman Telegram Club</a>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="contact-telegram">
                        <a href={tirangaLink}>Join Tiranga Telegram Club</a> 
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Contact