import React from 'react'

export default function Footer() {
    return (
        <footer>
            <hr class="text-light bg-light" />
            <div class="container pt-3">
                <div class="row">
                    <div class="col-md-4 mx-auto text-center">
                        <h3 className='text-white fw-bold'>Wingo Game</h3>
                    </div>
                </div>
            </div>
            <hr class="text-light bg-light mb-1" />
            <h2 class="text-light text-center mb-0 pb-2 py-2 fs-5 mb-4">Copyright &#169; 2010-2023 | Wingogame.org | All rights reserved</h2>
            <p className='text-center text-white mb-0 pb-4 container'><strong>Disclaimer:</strong> By accessing our fantasy game website, users acknowledge that all content is fictional, we do not guarantee accuracy, and users must conduct themselves responsibly, with all intellectual property rights reserved</p>
        </footer>
    )
}
